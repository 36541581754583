import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

function FormattedHTMLMessage({ id, values }) {
  const intl = useIntl();

  const defaultValues = {
    br: <br key="formatted-html-message-break" />,
    nbsp: <div key="formatted-html-message-nbsp" className="mx-1" />,
  };

  return <>{intl.formatMessage({ id }, { ...defaultValues, ...values })}</>;
}

FormattedHTMLMessage.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.shape({})),
};

FormattedHTMLMessage.defaultProps = {
  values: {},
};

export default FormattedHTMLMessage;
