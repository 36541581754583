/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";

// Locale data
// import enData from "react-intl/locale-data/en";
// import deData from "react-intl/locale-data/de";

// Messages
import en from "../locales/en.json";
import de from "../locales/de.json";

import PageLayout from "./pageLayout";
import CookieHandler from "./cookieHandler";

function flattenMessages(nestedMessages, prefix = "") {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const reducedMessages = { ...messages };
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      reducedMessages[prefixedKey] = value;
    } else {
      Object.assign(reducedMessages, flattenMessages(value, prefixedKey));
    }

    return reducedMessages;
  }, {});
}

const messages = { en: flattenMessages(en), de: flattenMessages(de) };

const Layout = ({ pathContext, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <IntlProvider
        locale={pathContext.locale}
        messages={messages[pathContext.locale]}
      >
        <CookieHandler>
          <PageLayout data={data}>{children}</PageLayout>
          <div id="modal-root" />
        </CookieHandler>
      </IntlProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export default Layout;
