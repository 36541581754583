// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-solutions-agencies-jsx": () => import("./../src/pages/solutions/agencies.jsx" /* webpackChunkName: "component---src-pages-solutions-agencies-jsx" */),
  "component---src-pages-solutions-entrepreneurs-jsx": () => import("./../src/pages/solutions/entrepreneurs.jsx" /* webpackChunkName: "component---src-pages-solutions-entrepreneurs-jsx" */),
  "component---src-pages-solutions-freelancer-and-consultants-jsx": () => import("./../src/pages/solutions/freelancer-and-consultants.jsx" /* webpackChunkName: "component---src-pages-solutions-freelancer-and-consultants-jsx" */),
  "component---src-pages-why-appointer-jsx": () => import("./../src/pages/why-appointer.jsx" /* webpackChunkName: "component---src-pages-why-appointer-jsx" */)
}

