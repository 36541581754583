import React from "react";
import cookies from "browser-cookies";
import { navigate } from "gatsby";
import appointerLogo from "../images/appointer-logo.png";
import FormattedHTMLMessage from "./FormattedHTMLMessage";
import Link from "./Link";
import ExternalLink from "./ExternalLink";

const Footer = () => {
  const switchLanguage = () => {
    const { pathname } = window.location;
    if (pathname.startsWith("/de")) {
      cookies.set("lang", "en");
      navigate(pathname.substr(3, pathname.length - 3));
    } else {
      cookies.set("lang", "de");
      navigate(`/de/${pathname.substr(1, pathname.length - 1)}`);
    }
  };
  return (
    <footer className="bg-cream-darker py-12">
      <div className="container mx-auto">
        <div className="flex flex-row">
          <div className="flex flex-col w-full md:w-1/2">
            <img src={appointerLogo} className="w-48 mb-4" alt="logo" />
            <div>
              <button
                className="link mt-3 flex flex-row  font-semibold"
                type="button"
                onClick={() => {
                  switchLanguage();
                }}
              >
                <FormattedHTMLMessage id="footer.lang-switch" />
              </button>
            </div>
            <div className="font-semibold mt-3">
              <button
                className="font-semibold"
                type="button"
                onClick={() => {
                  Metomic("ConsentManager:show");
                }}
              >
                <FormattedHTMLMessage id="footer.update-cookie" />
              </button>
            </div>

            <div className="mt-3 font-semibold">
              <Link to="/imprint">
                <FormattedHTMLMessage id="footer.imprint" />
              </Link>
            </div>
            <div className="mt-3 font-semibold">
              <ExternalLink to="https://appointer.zendesk.com/hc/de/sections/360002117994-DSGVO">
                <FormattedHTMLMessage id="footer.privacy" />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
