import React from "react";
import { Link as GatsbyLink } from "gatsby";
import cookies from "browser-cookies";

const Link = ({ to, children, ...props }) => {
  let currentLang = null;
  let localizedTo = to;

  if (typeof window !== "undefined") {
    if (cookies) {
      currentLang = cookies.get("lang");
    }
    localizedTo =
      currentLang && currentLang !== "en"
        ? `/${currentLang}${to}`
        : localizedTo;
  }

  return (
    <GatsbyLink to={localizedTo} {...props}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
