import React from "react";

export default ({ to, blank, children, ...props }) => (
  <a
    href={to}
    {...(blank ? { target: "_blank" } : {})}
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </a>
);
