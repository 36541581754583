/* eslint-env browser */
import { Component } from "react";

const APP_ID = "cu9j5ojk";

class Intercom extends Component {
  static inject(appId) {
    if (typeof window === "undefined") {
      return;
    }

    function i(...params) {
      i.c(params);
    }
    i.q = [];
    i.c = args => {
      i.q.push(args);
    };
    window.Intercom = i;

    const scriptTag = document.createElement("script");
    scriptTag.async = 1;
    scriptTag.src = `https://widget.intercom.io/widget/${appId}`;

    document.head.appendChild(scriptTag);
  }

  componentDidMount() {
    const { ...otherProps } = this.props;

    window.intercomSettings = {
      ...otherProps,
      app_id: APP_ID,
    };

    if (!window.Intercom) {
      Intercom.inject(APP_ID);
    }

    if (window.Intercom) {
      window.Intercom("boot", otherProps);
    }
  }

  componentDidUpdate() {
    const { ...otherProps } = this.props;
    if (APP_ID) {
      window.intercomSettings = { ...otherProps, app_id: APP_ID };
    }

    if (window.Intercom) {
      window.Intercom("update", otherProps);
    }
  }

  render() {
    return false;
  }
}

Intercom.displayName = "Intercom";

export default Intercom;
