import PropTypes from "prop-types";
import React from "react";
import Nav from "./navigation/Nav";
import { MobileQuery, TabletQuery } from "../lib/utils";
import MobileNav from "./navigation/MobileNav";

const Header = () => {
  return (
    <header>
      <MobileQuery>
        <MobileNav />
      </MobileQuery>
      <TabletQuery>
        <Nav />
      </TabletQuery>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
