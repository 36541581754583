import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import Link from "../Link";
import ExternalLink from "../ExternalLink";
import DropDownNav from "./DropDownNav";

const NavigationLinks = ({ sticks, onLinkClick }) => {
  const intl = useIntl();
  return (
    <>
      <Link
        to="/why-appointer"
        id="ga-nav-whyappointer"
        onClick={() => {
          onLinkClick();
        }}
      >
        <FormattedMessage id="nav.why-appointer" />
      </Link>
      <DropDownNav
        sticks={sticks}
        navTitle={intl.formatMessage({ id: "nav.solutions" })}
      >
        <Link
          to="/solutions/agencies/"
          id="ga-nav-solutions-agencies"
          onClick={() => {
            onLinkClick();
          }}
        >
          <FormattedMessage id="nav.agencies" />
        </Link>
        <Link
          onClick={() => {
            onLinkClick();
          }}
          to="/solutions/entrepreneurs/"
          id="ga-nav-solutions-entrepreneurs"
        >
          <FormattedMessage id="nav.entrepreneurs" />
        </Link>
        <Link
          onClick={() => {
            onLinkClick();
          }}
          to="/solutions/freelancer-and-consultants/"
          id="ga-nav-solutions-freelancer-consultants"
        >
          <FormattedMessage id="nav.freelancer-consultants" />
        </Link>
      </DropDownNav>

      <Link
        onClick={() => {
          onLinkClick();
        }}
        to="/pricing"
        id="ga-nav-pricing"
      >
        <FormattedMessage id="nav.pricing" />
      </Link>
      <ExternalLink
        to="https://meet.appointer.com/"
        className="ga-login-button"
      >
        <FormattedMessage id="nav.login" />
      </ExternalLink>
      <ExternalLink
        to="https://meet.appointer.com/"
        className="button ga-start-now-button"
      >
        <FormattedMessage id="nav.start-now" />
      </ExternalLink>
    </>
  );
};

NavigationLinks.propTypes = {
  sticks: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

NavigationLinks.defaultProps = {
  sticks: false,
  onLinkClick: () => {},
};

export default NavigationLinks;
