import React from "react";
import Responsive from "react-responsive";

export const CallbackOnEnter = callback => e => {
  e.persist();
  if (e.key === "Enter" && !e.shiftKey && typeof callback === "function") {
    callback(e);
  }
};

export const MobileBreakpoint = 768;

export const TabletBreakpoint = 1024;

export const isScreenMD =
  typeof window !== "undefined" && window.innerWidth > MobileBreakpoint;

export const isScreenLG = () => {
  return typeof window !== "undefined" && window.innerWidth > TabletBreakpoint;
};
// eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
export const DesktopQuery = props => (
  <Responsive {...props} minWidth={TabletBreakpoint + 1} />
);
// eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
export const MobileQuery = props => (
  <Responsive {...props} maxWidth={MobileBreakpoint} />
);

// eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
export const MobileAndTabletQuery = props => (
  <Responsive {...props} maxWidth={TabletBreakpoint} />
);

// eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
export const TabletQuery = props => (
  <Responsive {...props} minWidth={MobileBreakpoint + 1} />
);

export const fireEventToDataLayer = event => {
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({ event });
  }
};

export const fireCustomEventToDataLayer = data => {
  if (typeof dataLayer !== "undefined") {
    dataLayer.push(data);
  }
};
