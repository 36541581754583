import React, { useContext } from "react";
import Link from "../Link";

import LayoutContext from "../LayoutContext";

import appointerIcon from "../../images/appointer-icon.svg";
import appointerIconBw from "../../images/appointer-icon-bw.svg";
import appointerMarke from "../../images/appointer-marke.svg";
import NavigationLinks from "./NavigationLinks";

const Nav = () => {
  const { isScrollingToTop, isScrolling } = useContext(LayoutContext);

  return (
    <nav
      className={`nav fixed group top-0 left-0 z-50 w-full animate ${
        isScrollingToTop ? "bg-white shadow-lg" : "bg-white md:bg-transparent"
      }  ${
        isScrolling ? "is-scrolling hover:bg-white hover:shadow-lg" : "pt-4"
      }`}
    >
      <div className="flex flex-row w-full top-0 left-0 items-center animate">
        <Link to="/">
          <div className="flex flex-row">
            <img
              src={appointerIcon}
              className={`w-10 h-10 ${
                isScrollingToTop ? "block" : "hidden group-hover:block"
              }`}
              alt=""
            />
            <img
              src={appointerIconBw}
              className={`w-10 h-10 ${
                isScrollingToTop ? "hidden" : "block group-hover:hidden"
              }`}
              alt=""
            />
            <img
              src={appointerMarke}
              className={`w-40 h-10 pl-2 ${
                isScrolling
                  ? "opacity-0 group-hover:opacity-100"
                  : "opacity-100"
              } ${isScrollingToTop ? "opacity-100" : ""}`}
              alt=""
            />
          </div>
        </Link>
        <div
          className={`flex flex-col md:flex-row-reverse flex-grow items-center animate ${
            isScrollingToTop ? "opacity-100" : ""
          } ${isScrolling ? "opacity-0" : ""} group-hover:opacity-100`}
        >
          <div className="flex flex-col md:flex-row items-center">
            <NavigationLinks sticks={isScrolling} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
