import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

const DropDownNav = ({ navTitle, children, sticks }) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setDropDownIsOpen(false);
  }, [pathname]);
  return (
    <div className={`drop-down relative ${sticks ? "sticks" : ""}`}>
      <button
        onClick={() => {
          setDropDownIsOpen(!dropDownIsOpen);
        }}
        type="button"
      >
        {navTitle}
        <FontAwesomeIcon
          className={`flex-shrink-0 ml-1 transform animate ${
            dropDownIsOpen ? "rotate-180" : ""
          }`}
          icon={faChevronDown}
        />
      </button>
      <div className={`drop-down-menu ${dropDownIsOpen ? "is-open" : ""}`}>
        {children}
      </div>
    </div>
  );
};

DropDownNav.propTypes = {
  navTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  sticks: PropTypes.bool.isRequired,
};

export default DropDownNav;
