import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import Link from "../Link";

import appointerIcon from "../../images/appointer-icon.svg";
import appointerIconBw from "../../images/appointer-icon-bw.svg";
import appointerMarke from "../../images/appointer-marke.svg";
import NavigationLinks from "./NavigationLinks";
import ExternalLink from "../ExternalLink";

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const hideOnScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line
      window.addEventListener("scroll", hideOnScroll);
    }

    return function cleanup() {
      // eslint-disable-next-line
      window.removeEventListener("scroll", hideOnScroll);
    };
  });

  const { pathname } = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <nav
      className={`fixed group flex flex-col top-0 left-0 z-50 w-full animate ${
        isScrolling ? "" : "pt-3"
      } ${isScrolling || isOpen ? "bg-white shadow-lg" : ""} ${
        isOpen ? "h-screen" : ""
      }`}
    >
      <div className="flex flex-col w-full top-0 left-0 p-4 items-center animate h-full">
        <div className="flex flex-row items-center w-full">
          <div className="flex-col flex-no-shrink">
            <Link to="/">
              <div className="flex flex-row">
                <img
                  src={appointerIcon}
                  className={`w-10 h-10 ${
                    isScrolling || isOpen ? "block" : "hidden"
                  }`}
                  alt=""
                />
                <img
                  src={appointerIconBw}
                  className={`w-10 h-10 ${
                    isScrolling || isOpen ? "hidden" : "block"
                  }`}
                  alt=""
                />
                <img
                  src={appointerMarke}
                  className={`w-40 h-10 pl-2 animate ${
                    isScrolling && !isOpen ? "hidden" : "block"
                  }`}
                  alt=""
                />
              </div>
            </Link>
          </div>
          <div className="flex flex-col w-full items-end animate">
            <div className="flex flex-row justify-end items-center w-full">
              <div className={!isOpen && isScrolling ? "block" : "hidden"}>
                <ExternalLink
                  to="https://meet.appointer.com"
                  className="button shadow-none mx-6 ga-start-now-button "
                >
                  Start now
                </ExternalLink>
              </div>

              <button
                type="button"
                className={`flex items-center px-3 py-2 rounded text-black nav-icon flex-no-shrink ${
                  isOpen ? "open" : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`nav-mobile ${
            isOpen ? "block visible" : "hidden"
          } flex flex-row md:flex-row justify-center items-center w-full h-full pt-6`}
        >
          <div
            className={`flex flex-col ${
              isOpen ? "bg-white opacity-100" : "opacity-0"
            } w-full items-center h-full`}
          >
            <NavigationLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;
