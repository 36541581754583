import { useEffect } from "react";
import TagManager from "react-gtm-module";

const GoogleTagManager = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-5K5BHCT",
    });
  });

  return false;
};

export default GoogleTagManager;
