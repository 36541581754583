import React from "react";
import cookies from "browser-cookies";
import { navigate } from "gatsby";
import detectBrowserLanguage from "detect-browser-language";
import Layout from "./src/components/layout";

const supportedLanguagePaths = ["de"];

// You can delete this file if you're not using it
export const onInitialClientRender = () => {
  let currentPath = window.location.pathname;

  // redirect to the correct sub-site on first page view
  let langCookie = cookies.get("lang");
  if (langCookie === null) {
    let language = (detectBrowserLanguage() || "").substr(0, 2);

    if (!supportedLanguagePaths.includes(language)) {
      language = "en";
    }

    if (
      undefined !==
      supportedLanguagePaths.find(lang => currentPath.startsWith(`/${lang}`))
    ) {
      currentPath = currentPath.substr(3, currentPath.length - 3);
    }

    if (language !== "en") {
      // because en is our base path
      currentPath = `/${language}${currentPath}`;
    }

    cookies.set("lang", language);
    navigate(currentPath);
    return;
  }

  if (!supportedLanguagePaths.includes(langCookie) && langCookie !== "en") {
    cookies.set("lang", "en");
    langCookie = "en";
  }

  if (langCookie === "de" && !currentPath.startsWith("/de")) {
    navigate(`/de${currentPath}`);
    return;
  }
};

export const onRouteUpdate = ({ location }) => {
  const page = location
    ? location.pathname + location.search + location.hash
    : undefined;

  if (typeof window.ga === "function") {
    window.ga("set", "page", page);
    window.ga("send", "pageview");
  }

  if (typeof window.dataLayer === "object") {
    window.dataLayer.push({
      event: "pageChanged",
      url: page,
    });
  }
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
