import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import Header from "./header";
import Footer from "./footer";
import LayoutContext from "./LayoutContext";

import "../styles/index.scss";

let scrollTimer = 0;

const PageLayout = ({ children, data }) => {
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isScrollingToTop, setIsScrollingToTop] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollHandler = () => {
    window.clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      const scrollPos = window.scrollY;
      if (scrollPos > 0) {
        setIsScrolling(true);
        setLastScrollPos(scrollPos);

        if (lastScrollPos > scrollPos) {
          setIsScrollingToTop(true);
        } else {
          setIsScrollingToTop(false);
        }
      } else {
        setIsScrolling(false);
        setIsScrollingToTop(false);
      }
    }, 60);
  };

  const updateVh = () => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line
      const vh = window.innerHeight * 0.01;
      // eslint-disable-next-line
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateVh();
      // eslint-disable-next-line
      window.addEventListener("scroll", scrollHandler);
      // eslint-disable-next-line
      window.addEventListener("resize", updateVh);
    }

    return function cleanup() {
      // eslint-disable-next-line
      window.removeEventListener("scroll", scrollHandler);
      // eslint-disable-next-line
      window.removeEventListener("resize", updateVh);
    };
  });

  return (
    <LayoutContext.Provider value={{ isScrollingToTop, isScrolling }}>
      <div className="flex flex-col min-h-mobile-safe-screen justify-between">
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            <main>{children}</main>
          </div>
        </div>
        <Footer />
      </div>
    </LayoutContext.Provider>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

export default PageLayout;
