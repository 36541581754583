import React from "react";
import { MetomicProvider, ConsentGate } from "@metomic/react";
import GoogleTagManager from "../lib/GoogleTagManager";
import Intercom from "../lib/Intercom";

const CookieHandler = ({ children }) => {
  return (
    <MetomicProvider
      projectId="prj:8a466907-978c-4653-a828-538446e43669"
      autoblocking
    >
      <>
        <ConsentGate micropolicy="statistics">
          <GoogleTagManager />
        </ConsentGate>
        <ConsentGate micropolicy="chat">
          <Intercom />
        </ConsentGate>
        {children}
      </>
    </MetomicProvider>
  );
};

export default CookieHandler;
